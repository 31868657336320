document.addEventListener('turbolinks:load', () => {
  setupFacetFeedbackActions();
});

const setupFacetFeedbackActions = () => {
  const sliders = document.querySelectorAll('.wcs-range-slider');

  if (!sliders) return;

  sliders.forEach((slider) => {
    const handleSliderChange = (event) => {
      const sliderValue = event.target.value;
      const textField = document.getElementById(`text-field-container-${slider.id}`);
      const facetDetails = document.getElementById(`facet-details-${slider.id}`);

      if (sliderValue < 0) {
        if (!textField.classList.contains('invisible')) return;
        textField.classList.remove('opacity-0', 'invisible', 'pointer-events-none', 'h-0');
        textField.classList.add('opacity-100', 'h-full');
        textField.focus();

        facetDetails.classList.remove('opacity-100', 'flex-grow');
        facetDetails.classList.add('opacity-0', 'invisible', 'h-0');

        setTimeout(() => {
          facetDetails.classList.add('invisible');
        }, 500); // Match the duration of the fade-out transition
      } else {
        if (!facetDetails.classList.contains('invisible')) return;

        textField.blur();
        textField.classList.remove('opacity-100', 'h-full');
        textField.classList.add('opacity-0', 'pointer-events-none', 'h-0');
        
        setTimeout(() => {
          textField.classList.add('invisible');
          facetDetails.classList.remove('opacity-0', 'invisible', 'h-0');
          facetDetails.classList.add('opacity-100', 'flex-grow');
        }, 500); // Match the duration of the fade-out transition
      }
    };

    slider.addEventListener('change', handleSliderChange);
  });
}
